@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* min width break points
  --xs: 576px;
  --sm: 576px;
  --md: 768px;
  --lg: 992px;
  --xl: 1200px;
*/

:root {
  --borderRadius: 10px;
  --blue1: #32A8ED;
  --blue1Dark: #258dca;
  --darkGrey: #303030;
  --lightGrey: #f6f6f6;
  --veryLightGreyText: #e0e8f0;
  --grey: #8099a9;
  --transitionTime: 0.3s;
  --dropShadow: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

/* FOR ALL PAGES */
a {
  color: #32A8ED;
  color: var(--blue1);
  transition: color 0.3s;
  transition: color var(--transitionTime);
}
a:hover {
  text-decoration: none;
  color: #258dca;
  color: var(--blue1Dark);
}


body {
  font-family: Roboto, 'Segoe UI', sans-serif;
  font-weight: 300;
  font-size: 1.25rem;
}

.center {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.outlinedImage {
  border: solid 1px #D6D6D6;
}

.roundedImage {
  border-radius: 10px;
  border-radius: var(--borderRadius);
}

.dropShadow {
  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  -webkit-filter: var(--dropShadow);
          filter: var(--dropShadow);
}

.linkedInLogo {
  width: 110px;
}

.alert {
  position: fixed;
  top: 30px;
  width: calc(100% - 60px);
  left: 30px;
  text-align: center;
  border-radius: 10px;
  border-radius: var(--borderRadius);
  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  -webkit-filter: var(--dropShadow);
          filter: var(--dropShadow);
  transition: opacity 0.3s;
  transition: opacity var(--transitionTime);
  opacity: 1;
}

.alert.opacity0 {
  opacity: 0;
}


th {
  font-weight: 500;
}

.opacity0 {
  opacity: 0;
  transition: opacity 0.3s;
  transition: opacity var(--transitionTime);
}

.opacity1 {
  opacity: 1;
  transition: opacity 0.3s;
  transition: opacity var(--transitionTime);
}

.height0 {
  height: 0;
}

.displayNone {
  display: none;
}


.borderRadius {
  border-radius: 10px;
  border-radius: var(--borderRadius);
}

.form-check-input {
  position: static;
  margin-top: 0;
  margin-left: 0;
  margin-right: 1rem;
  margin-bottom: 0px;
}
.form-check-label {
  cursor: pointer;
}
.captcha {
  margin-bottom: 70px;
}

/* End of FOR ALL PAGES */



/* Modal */

.modal-dialog, .modal {
  margin: 20px;
  width: calc(100% - 40px);
  max-width: none;
  max-width: initial;
  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  -webkit-filter: var(--dropShadow);
          filter: var(--dropShadow);
}

.modalX {
  cursor: pointer;
  font-weight: 400;
  font-size: 25px;
  color: #666;
  transition: color 0.3s;
  transition: color var(--transitionTime);
}

.modalX:hover {
  color: black;
}

.modal-content {
  border-radius: 10px;
  border-radius: var(--borderRadius);
}

.modal-header {
  padding-left: 25px;
  padding-right: 25px;
}

.modal-footer {
  padding-right: 25px;
}




/* dropdown */
.defaultDropdown {
  border-radius: 10px;
  border-radius: var(--borderRadius);
  border: solid 1px #aaaaaa;
  padding: 13px 22px;
  margin-right: 20px;
  margin-bottom: 20px;
}

@media (min-width: 486px) {
  /* if screen is wide enough, put multiple buttons on same line */
  .dropdown {
    display: inline-block;
  }
}

@media (max-width: 486px) {
  .defaultDropdown {
    width: 100%;
  }

  .dropdown-menu {
    width: 100%;
    text-align: center;
  }
}


.defaultDropdown:hover {
  border: solid 1px #888888;
  background-color: #eef0f2;
}

.defaultDropdown:focus,
.show > .defaultDropdown {
  box-shadow: none;
  border: solid 1px #888888;
  border-color: #888888 !important;
}

.careersPage .defaultDropdown::after{
  margin-left: 1.3rem;
}

.dropdown-menu {
  border-radius: 7px;
}

.dropdown-item:active, .dropdown-item.active, .dropdown-item:focus {
  color: black;
  background-color: #f0f0f3;
}



/* Text colors and links */

.textLink {
  font-weight: 500;
  display: inline-block;
  cursor: pointer;
  color: #32A8ED;
  color: var(--blue1);
  transition: color 0.3s;
}

.textLink:hover {
  color: #258dca;
  color: var(--blue1Dark);
}

.blueText {
  color: #32A8ED;
  color: var(--blue1);
}
.darkBlueText {
  color: #258dca;
  color: var(--blue1Dark);
}
.veryLightGreyText {
  color: #e0e8f0;
  color: var(--veryLightGreyText);
}
.greyText {
  color: #8099a9;
  color: var(--grey);
}



/* Buttons */

.defaultButton {
  min-width: 173px;
  height: 52px;
  border-radius: 10px;
  border-radius: var(--borderRadius);
  background-color: #32A8ED;
  background-color: var(--blue1);
  border: none;
  padding-left: 30px;
  padding-right: 30px;
}

.defaultButton:hover {
  background-color: #258dca;
  background-color: var(--blue1Dark);
}

.defaultButton:focus {
  outline: none;
  background-color: #258dca !important;
  background-color: var(--blue1Dark) !important;
  box-shadow: none !important;
}

.defaultButton:active {
  background-color: #258dca !important;
  background-color: var(--blue1Dark) !important;
}

.whiteButton, .whiteButton:focus {
  background-color: white !important;
  color: black;
}

.whiteButton:hover {
  background-color: #f0f0f0 !important;
  color: black;
}

.whiteButton:active {
  background-color: #e0e0e0 !important;
  color: black !important;
}



/* nav bar */

.NavLink, .nav-link {
  color: #888888;
  transition: color 0.3s;
  font-size: 1rem;
}

.navbar {
  border-bottom: solid 1px #666666;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-weight: 500;
}

@media (min-width: 992px) {
  .NavLink, .nav-link {
    margin-left: 10px;
    margin-right: 10px;
    margin-top:  5px;
    margin-bottom: 5px;
  }
}

.NavLink:hover {
  color: #000000;
}

#logoImage {
  width: 38px;
  margin-right: 10px;
  position: relative;
  bottom: 2px;
}

.dropdown-menu {
  min-width: 13rem;
}

.dropdown-header {
  color: #909090;
}

.dropdown-item-text {
  font-weight: 400;
  transition: background-color 0.3s ease;
}

a .dropdown-item-text:hover {
  background-color: #f4f4f4;
}




/* Title Sections (first section that usually contains the page title) */

.titleHeader {
  font-family: Roboto, 'Segoe UI', sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 300;
  line-height: 42px;
  letter-spacing: 0rem;
  text-align: center;
  margin-bottom: 60px;
}

.blueGradientBackground .titleHeader {
  font-weight: 400;
  color: white;
}

.mainContainer {
  margin-top: 170px;
  margin-bottom: 170px;
}

.jumbotron {
  margin-bottom: 0;
  border-radius: 0;
}

.jumbotron .mainContainer {
  margin-top: 100px;
  margin-bottom: 100px;
}

.darkBackground {
  background-color: #303030;
  background-color: var(--darkGrey);
}

.darkBackground .titleHeader {
  color: white;
  font-weight: 400;
}

.lightGreyBackground {
  background-color: #f6f6f6;
  background-color: var(--lightGrey);
}

/*
.blueGradientBackground {
  background: linear-gradient(90deg, #1488d0 0%, #1B96DE 50%, #1488d0 100%);
}
@media (max-width: 992px) {
  .blueGradientBackground {
    background: linear-gradient(90deg, #1890D8 0%, #1B96DE 50%, #1890D8 100%);
  }
}*/
.blueGradientBackground {
  background-color: #258dca;
  background-color: var(--blue1Dark)
}

.darkRadialBackground {
  background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.31) 62.12%, rgba(0, 0, 0, 0.00) 100%);
  padding: 30px 0;
}


.imageBackground {
  object-fit: cover;
  background-position: center;
}

.imageBackground .titleHeader {
  color: white;
  font-weight: 400;
}

.imageBackground .subHeader {
  color: white;
  font-weight: 300;
}

.subHeader {
  text-align: center;
}




/* Cards */

.card {
  border-radius: 10px;
  border-radius: var(--borderRadius);
  margin-bottom: 30px;
}

.card-title {
  font-weight: 500;
  text-align: center;
  font-size: 1.2rem;
  margin: 35px 0 25px 0;
}

.card-body {
  padding: 40px 30px;
  font-size: 1.25rem;
  line-height: 1.5rem;
}

.cardBodyWithBottomButton {
  margin-bottom: 80px;
}

.card-body button {
  margin-top: 15px;
}

.cardBottomButton {
  position: absolute;
  bottom: 40px;
}

.card-body img {
  max-height: 89px;
  width: auto;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.cardImageCap {
  height: 200px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
}

.resourcesPage .cardImage {
  max-height: 150px;
  max-width: 100%
}

/* these are cards with a lot of text. Adding extra padding */
.wideCard .card-body {
  padding: 30px 12% 70px 12%;
}


.careersPage .card-title {
  margin-top: 5px;
}

.careersPage .card-body {
  line-height: 2rem;
}

.careersPage .cardBodyWithBottomButton {
  margin-bottom: 60px;
}




/* Carousel */
.carousel {
  margin-top: 100px;
  margin-bottom: 88px;
}
.carousel p, .carousel .card-title {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.carousel img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.carousel .card {
  margin: 0px 15px 12px 15px;
  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.displayNone {
  display: none;
}

#carouselLeftArrow {
  left: 20px;
}
#carouselRightArrow {
  right: 20px;
}
#carouselLeftArrow, #carouselRightArrow {
  position: absolute;
  top: 510px;
  color: white;
  font-size: 50px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
}

@media (max-width: 420px) {
  #carouselLeftArrow {
    left: 0px;
  }
  #carouselRightArrow {
    right: 0px;
  }
}


.carousel .cardBodyWithBottomButton {
  margin-bottom: 40px;
}

.carousel .cardBottomButton {
  position: absolute;
  bottom: 20px;
}

.carousel li {
  display: flex;
}

.react-multi-carousel-item.react-multi-carousel-item--active {
  justify-content: center;
}

/* offset sections*/
.offsetSection .sectionHeader {
  margin-bottom: 1.5rem;
}
.offsetSection .sectionOneLiner {
  color: #258dca;
  color: var(--blue1Dark);
  margin-bottom: 1.5rem;
}

.offsetSection img {
  max-width: 319px;
  margin-bottom: 50px;
  object-fit: cover;
}

.offsetSection.offsetRightSection img {
  margin-right: 90px;
}
.offsetSection.offsetLeftSection img {
  margin-left: 100px;
}

@media (max-width: 767.5px) {
  .offsetSection.offsetRightSection img,
  .offsetSection.offsetLeftSection img {
    margin: 0px 0px 80px 0px;
    width: calc(100% - 20px);
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  .sectionHeader, .sectionOneLiner {
    text-align: center;
  }
}





/* check list header section */
.headerChecklistContainer {
  text-align: center;
}

.headerChecklistContainer .check {
  width: 2rem;
  height: 2rem;
  fill: black;
  margin-right: 10px;
  position: relative;
  bottom: 0.15rem;
}

.checklistEntry {
  color: black;
  font-weight: 400;
  margin-bottom: 30px;
}

@media (min-width: 600px) {
  .checklistEntry {
    position: relative;
    right: 1rem; /* the text should be centered without checkmarks */
  }
}

.headerChecklistContainer button {
  margin-top: 40px;
}





/* Forms */

.formLabel {
  margin-bottom: 20px;
}

.formInput {
  margin-bottom: 70px;
  border-radius: 10px;
  border-radius: var(--borderRadius);
  border: solid 1px black;
  height: calc(1.5em + .75rem + 6px);
  padding-left: 16px;
}

select {
  cursor: pointer;
  transition: all 1 ease;
}


select:hover {
  border: solid 1px #258dca;
  border: solid 1px var(--blue1Dark);
}

textarea.formInput {
  padding: 12px 16px;
}

.formInput:focus {
  box-shadow: none;
  border: solid 1px black;
}





/* Styles related to the individual pages for posts on the resources section */

.resourcesPost h5 {
  margin-bottom: 2rem;
}
.resourcesPost p {
  margin-bottom: 6rem;
}





/* Miscellaneous */

.otherContactIcon {
  margin-right: 20px;
}

.otherContactLink {
  color: black;
}

.adminPage {
  max-width: 100%;
}

.clickableRow {
  cursor: pointer;
}

.nav-tabs .nav-item {
  font-weight: 400;
}

.jobListingPage h4 {
  margin-bottom: 2rem;
}

.jobListingPage p {
  margin-bottom: 5rem;
}

.dropzone {
  padding: 8px 16px;
  border-width: 2px;
  border-color: grey;
  border-style: dashed;
  background-color: white;
  color: #777777;
  outline: none;
  transition: border .24s ease-in-out;
  border-radius: 10px;
  border-radius: var(--borderRadius);
  cursor: pointer;
  margin-bottom: 0px;
}

.dropzone.dragAccept {
  border-color: grey;
}

.dropzone.dragReject {
  border-color: grey;
}

.dropzone.dragActive {
  border-style: solid;
}



#jarredHeadshot {
  margin-bottom: 80px;
  max-height: 400px;
}

.companyLogos img {
  margin: 20px 40px;
}


.aboutUsPage .card img {
  margin-top: 30px;
}

